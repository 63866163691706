<template>
  <div class="flex flex-col gap-y-0 lg:gap-y-6">
    <div class="bg-white h-56 px-16 hidden lg:flex items-center">
      <h1 class="font-EffraR text-textBgGrey text-base">Consultations</h1>
    </div>

    <div
      class="
        w-full
        lg:w-11/96 lg:mx-auto
        bg-white
        rounded-none
        lg:rounded-lg
        p-5
        flex flex-col
        gap-y-8
      "
    >
      <template v-if="!loader">
        <header
          class="flex flex-col lg:flex-row justify-between gap-y-3 items-center"
        >
          <h1 class="flex-1 text-xl font-EffraM">Liste des consultations</h1>
          <div
            class="
              flex flex-col
              md:flex-row
              gap-x-4 gap-y-3
              items-start
              md:items-end
            "
          >
            <dok-select
              :d-model.sync="search.status_filter"
              size="lg"
              custom-class="w-full"
            >
              <template v-slot:options>
                <option :value="null" disabled selected>
                  Filtrer les données
                </option>
                <option value="ACCEPTED">Accepté</option>
                <option value="PENDING">En attente</option>
                <option value="TRANSFERED">Reporté</option>
                <option value="REJECTED">Annulé</option>
              </template>
              <template v-slot:rightIcon>
                <img src="/svg/chevron-down .svg" />
              </template>
            </dok-select>
            <div class="flex flex-row items-center gap-x-1 md:gap-x-4">
              <dok-input
                :d-model.sync="search.term"
                size="lg"
                d-placeholder="Recherche par nom du patient"
                custom-class="w-auto lg:w-300"
              >
              </dok-input>
              <dok-button
                size="lg"
                bg="grayBorder"
                @click.native="getConsultations"
              >
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM21 21l-4.35-4.35"
                    stroke="#A8AFB6"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </dok-button>
            </div>
          </div>
        </header>

        <hr style="border: 1px solid #eee" />

        <div class="flex flex-col gap-y-3">
          <div
            class="
              hidden
              lg:grid
              grid-cols-7
              gap-x-3
              bg-dokBlue-lighterr
              h-56
              items-center
              px-4
              rounded-lg
            "
          >
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black">Patient</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="cursor-pointer hover:text-dokBlue-dark"
                  :class="search.sortBy === 'name' ? 'text-dokBlue-ultra' : ''"
                  @click="sortBy('name')"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 15l-6-6-6 6"
                  />
                </svg>
              </div>
            </div>
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black"
                  >Date de consultation</span
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="cursor-pointer hover:text-dokBlue-dark"
                  :class="search.sortBy === 'date' ? 'text-dokBlue-ultra' : ''"
                  @click="sortBy('date')"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 15l-6-6-6 6"
                  />
                </svg>
              </div>
            </div>
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black"
                  >Motif de consultation</span
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="cursor-pointer hover:text-dokBlue-dark"
                  :class="search.sortBy === 'motif' ? 'text-dokBlue-ultra' : ''"
                  @click="sortBy('motif')"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 15l-6-6-6 6"
                  />
                </svg>
              </div>
            </div>
            <div class="font-EffraR text-base text-black">
              Statut du rendez-vous
            </div>
            <div class="font-EffraR text-base text-black">
              Statut du patient
            </div>
            <div class="font-EffraR text-base text-black col-span-2">
              <span class="sr-only">Actions</span>
            </div>
          </div>
          <!-- LIST ITEMS -->
          <div class="flex flex-col gap-y-4" id="ListItems">
            <div
              class="
                grid grid-cols-1
                lg:grid-cols-7
                gap-x-3 gap-y-3
                bg-transparent
                h-auto
                lg:h-56
                items-center
                px-4
                py-5
                lg:py-0
                rounded-lg
                md:rounded-none
              "
              style="border: solid 1px #e7ecf2"
              v-for="(consultation, i) in dataList"
              :key="i"
            >
              <!---- Full Name - -->
              <div class="font-EffraM text-lg text-black">
                {{
                  consultation.user && consultation.user.firstName
                    ? consultation.user.firstName
                    : "Créneau"
                }}
                {{
                  consultation.user && consultation.user.lastName
                    ? consultation.user.lastName
                    : "réservé"
                }}
              </div>
              <!---- Date - -->
              <div class="font-EffraR text-base" style="color: #868c92">
                {{
                  _moment()(consultation.start).format("DD MMMM YYYY à HH:mm")
                }}
              </div>
              <!---- Consultation Category - -->
              <div class="flex flex-row items-center gap-x-2">
                <div
                  :style="`width: 12px; height: 12px; background: ${consultation.category.color};`"
                  class="bg-reddok rounded-full"
                ></div>
                <span class="font-EffraR text-base text-black">
                  {{ consultation.category.title }}
                </span>
              </div>
              <!---- Consultation Status Appointment - -->
              <div class="font-EffraR">
                {{ $statusConsultation(consultation.status) }}
              </div>
              <!---- Consultation Status - -->
              <div>
                <span
                  class="font-EffraR text-graylabel"
                  v-if="consultation.status === 'CANCELED'"
                >
                  {{ $statusConsultation(consultation.state) }}
                </span>
                <div v-if="consultation.status !== 'CANCELED'">
                  <dok-select
                    :d-model.sync="consultation.state"
                    size="sm"
                    custom-class="w-full border-0 hover:bg-dokBlue-lighterrr focus:border-2 focus:border-solid focus:border-borderGraySelect focus:bg-transparent"
                    :change-value="onChangeStatus(consultation)"
                    :no-border="true"
                  >
                    <template v-slot:options>
                      <option value="IN-PROGRESS">En consultation</option>
                      <option value="IN-WAITING-ROOM">
                        En salle d'attente
                      </option>
                      <option value="JUSTIFIED-ABSENCE">
                        Absence justifiée
                      </option>
                      <option value="UNJUSTIFIED-ABSENCE">
                        Absence injustifiée
                      </option>
                      <option value="INDEFINITE">Indéfini</option>
                      <option value="DONE">Consultation terminée</option>
                    </template>
                    <template v-slot:rightIcon>
                      <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m4.5 6.75 4.5 4.5 4.5-4.5"
                          stroke="#333638"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </template>
                  </dok-select>
                </div>
              </div>
              <!---- Consultation Actions - -->
              <div class="flex flex-col lg:flex-row gap-x-2 lg:col-span-2">
                <dok-button
                  custom-class="flex items-center gap-x-2 text-left hover:bg-dokBlue-lighter hover:text-dokBlue-ultra"
                  :class="noSubscribed ? 'line-through' : ''"
                  bg="none"
                  @click.native="displayConsultation(consultation)"
                >
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clip-path="url(#a)"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z"
                      />
                      <path
                        d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5z"
                      />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M0 0h18v18H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                  Voir la consultation
                </dok-button>
                <dok-share-consultation
                  :data="consultation"
                  :class="noSubscribed ? 'line-through' : ''"
                  aria-label="Partage la consultation"
                  custom-class="w-full"
                >
                </dok-share-consultation>
              </div>
            </div>
            <template v-if="onClickedLoadMore">
              <div
                v-for="(ix, index) in Array(5)"
                :key="`loader-${index}`"
                class="
                  grid grid-cols-1
                  lg:grid-cols-6
                  gap-x-0 gap-y-3
                  bg-transparent
                  h-auto
                  lg:h-56
                  items-center
                  px-0
                  rounded-lg
                  shadow
                "
              >
                <!---- Full Name - -->
                <div
                  class="
                    bg-graydok
                    h-full
                    animate-pulse
                    font-EffraM
                    text-lg text-black
                  "
                >
                  &ensp;
                </div>
                <!---- Date - -->
                <div
                  class="bg-graydok h-full animate-pulse font-EffraR text-base"
                  style="color: #868c92"
                >
                  &ensp;
                </div>
                <!---- Consultation Category - -->
                <div
                  class="
                    bg-graydok
                    h-full
                    animate-pulse
                    flex flex-row
                    items-center
                    gap-x-2
                  "
                >
                  &ensp;
                </div>
                <!---- Consultation Status Appointment - -->
                <div class="bg-graydok h-full animate-pulse font-EffraR">
                  &ensp;
                </div>
                <!---- Consultation Status - -->
                <div class="bg-graydok h-full animate-pulse">&ensp;</div>
                <!---- Consultation Actions - -->
                <div
                  class="bg-graydok h-full animate-pulse flex flex-row gap-x-2"
                >
                  &ensp;
                </div>
              </div>
            </template>
          </div>
          <!-- Null Data Consultations -->
          <div
            v-if="!dataList.length"
            class="px-8 py-3 w-full flex justify-center items-center"
          >
            <img src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
          </div>
        </div>

        <div class="flex justify-center items-center">
          <dok-button
            bg="trans"
            size="lg"
            custom-class="w-full lg:w-336"
            @click.native="onMoreData"
            v-if="dataList.length && !onClickedLoadMore && showButtonLoadMore"
          >
            Voir Plus
          </dok-button>
        </div>
      </template>
      <template v-if="loader">
        <div class="w-300 mx-auto px-5">
          <div class="p-5 px-8 flex justify-center gap-x-8">
            <div
              class="
                bg-dokBlue-light
                w-8
                h-8
                rounded-full
                animate-bounce
                blue-circle
              "
            ></div>
            <div
              class="
                bg-rosDok-ultra
                w-8
                h-8
                rounded-full
                animate-bounce
                red-circle
              "
            ></div>
            <div
              class="
                bg-dokBlue-ultra
                w-8
                h-8
                rounded-full
                animate-bounce
                green-circle
              "
            ></div>
          </div>
        </div>
      </template>
      <!-- <template v-if="noSubscribed">
        <div class=" mx-auto px-5 font-black text-3xl">
          {{noSubscribedMessage}}
        </div>
      </template> -->
    </div>

    <!-- Modal Form Create Or Update Appointment -->
    <modal
      :is-active="isCreationModalVisible"
      :exit-modal="
        () => {
          isCreationModalVisible = false;
        }
      "
      :no-padding="false"
      :width="500"
    >
      <div>
        <div
          class="
            py-2
            px-20
            text-black text-base text-center
            cursor-pointer
            border-0 border-b-2 border-solid border-dokBlue-ultra
            font-EffraM
          "
        >
          Créer une nouvelle consultation
        </div>
      </div>
      <div>
        <create-form :form.sync="form"></create-form>
      </div>
    </modal>
  </div>
</template>

<script>
  import { EventBus } from "@/event-bus";
  import { mapActions } from "vuex";
  import moment from "moment";
  const modal = () => import("@/views/global-components/dragModal");
  const createForm = () =>
    import("@/views/dashboard/agenda/layouts/form/index.vue");
  // const birthDay = () => import("@/views/global-components/birthday");

  export default {
    components: { modal, createForm },
    data() {
      return {
        paginate: {
          page: 1,
        },
        search: {
          term: null,
          status_filter: null,
          sortBy: null,
        },
        modal: {
          share: false,
        },
        form: {
          startAt: moment().clone(),
          categoryId: null,
          type: "UNPLANNED-CONSULTATION",
          fromHour: {},
          toHour: {},
        },
        shareWith: {
          data: {},
        },
        isCreationModalVisible: false,
        loader: true,
        termSearch: "",
        dataList: [],
        showMore: true,
        onClickedLoadMore: false,
        showButtonLoadMore: true,
        noSubscribed: false,
        noSubscribedMessage:
          "Votre abonnement est expiré, merci de le renouveler",
      };
    },
    created() {
      this.noSubscribed = this.$auth.isNotSubscribed();
      this.getConsultations();
      this.$store.dispatch("agenda/GET_DOCTOR_CATEGORIES", { onData: () => {} });
    },
    mounted() {
      EventBus.$on("Create_New_Consultation", this.onCreateConsultation);
      EventBus.$on("Modal_Has_New_Success_Appointment", (data) => {
        if (this.$route.name === "consultations") {
          this.$router.push({
            name: "folders_detail",
            params: {
              id: data._id,
            },
          });
          this.isCreationModalVisible = false;
        }
      });
    },
    methods: {
      ...mapActions("agenda", ["UPDATE_EVENT"]),
      ...mapActions("user", ["GET_APPOINTMENTS"]),
      async onMoreData() {
        this.paginate.page++;
        this.onClickedLoadMore = true;

        await this.GET_APPOINTMENTS({
          params: {
            ...this.paginate,
            ...this.search,
          },
          onData: ({ ok, data }) => {
            if (ok) {
              let scrollContent = document.querySelector(".scroll-content");
              scrollContent.scrollIntoView(false);

              if (!data.length) {
                this.showButtonLoadMore = false;
              }

              this.dataList = [...this.dataList, ...data];

              this.onClickedLoadMore = false;
            }
          },
        });
      },
      async getConsultations() {
        this.loader = true;
        await this.GET_APPOINTMENTS({
          params: this.search,
          onData: ({ ok, data, subscribed, message }) => {
            if (ok) {
              this.dataList = data;
              this.showMore = true;
            } else if (!subscribed) {
              // this.noSubscribed = true
              // this.noSubscribedMessage = message
            }

            this.$nextTick(() => {
              this.loader = false;
            });
          },
        });
      },
      sortBy(type) {
        this.search.sortBy = type;
        this.$nextTick(() => {
          this.getConsultations();
        });
      },
      onCreateConsultation() {
        this.form = {
          startAt: moment().clone(),
          categoryId: null,
          type: "UNPLANNED-CONSULTATION",
          fromHour: {},
          toHour: {},
        };
        this.isCreationModalVisible = true;
      },
      onChangeStatus(form) {
        return async () => {
          if (!form._id) return;

          this.$vs.loading();

          const { data, ok } = await this.UPDATE_EVENT(form);

          if (ok) {
            this.$vs.loading.close();
            return EventBus.$emit("flashDok", {
              type: "success",
              message: "Le statut du rendez-vous a été mis à jour avec succès!",
              timerAlert: 8000,
            });
          }

          this.$vs.loading.close();
        };
      },
      onCancelConsultationCalled(consultationId) {
        this.$vs.loading();
        this.$store.dispatch("doctor/UPDATE_APPOINTMENT", {
          data: { status: "CANCELED" },
          _id: consultationId,
          onData: ({ ok, message }) => {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 4000,
              text: message,
              color: ok ? "success" : "danger",
              iconPack: "feather",

              icon: "icon-check",
            });
            if (ok) {
              this.appointments.status = "CANCELED";
            }
          },
        });
      },
      checkStatusColor(status) {
        let color = null;
        if (status === "IN-PROGRESS") color = "#007bff";
        if (status === "DONE") color = "#28a745";
        if (status === "PENDING") color = "#CCC";
        if (status === "CANCELED") color = "#dc3545";
        if (status === "REJECTED") color = "#e00000";
        if (status === "TRANSFERED") color = "#33a2b8";
        if (status === "ACCEPTED") color = "#5db463";

        return color || "#000";
      },
      displayConsultation(consultation) {
        if (!this.noSubscribed) {
          this.$router.push(`/consultations/${consultation._id}`);
        } else {
          alert(this.noSubscribedMessage);
        }
      },
      _moment() {
        return moment;
      },
    },
  };
</script>

<style lang="scss" scoped>
.status-border {
  @apply border-0 border-b-2 border-dashed pb-1;
}

.button-tooltip {
  position: relative;
}

.button-tooltip::after {
  content: attr(data-tooltip);
  background: #383838;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  color: white;
  height: 34px;
  bottom: -45px;
  left: -100px;
}

.button-tooltip::after,
.button-tooltip::before {
  position: absolute;
  display: none;
}

.button-tooltip:hover::after,
.button-tooltip:hover::before {
  display: block;
  @apply flex items-center justify-center font-EffraR px-5;
}

@screen sm {
  .button-tooltip::after {
    left: -150px;
  }
}

@screen md {
  .button-tooltip::after {
    left: -100px;
  }
}

@screen lg {
  .button-tooltip::after {
    left: -10px;
  }
}

@screen xl {
  .button-tooltip::after {
    left: -100px;
  }
}
</style>
